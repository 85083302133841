/*********************
TYPOGRAPHY - http://cssfontstack.com/
*********************/

@import 'https://fonts.googleapis.com/css?family=Rubik:300,400,500';

// 'Better Helvetica' via http://css-tricks.com/snippets/css/better-helvetica/
$sans:				"Rubik", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$serif:				"Georgia", "Times New Roman", serif;

$font_heading:		$sans;
$font_body:			$sans;

$base_font_size: 	16px;

body {
	font-weight: 300;
	font-family: $font_body;
	font-size: $base_font_size;
	color: $color_gray_dark;
	-webkit-font-smoothing: antialiased;
	//text-rendering: optimizeLegibility; // Use with caution. May break certain @font-face fonts.
}

h1, h2, h3,
h4, h5, h6 {
	font-family: $font_heading;
	// font-weight: 500;
}

h3 {
	font-size: 1.75em;
	color: $color_gray_dark;
	.theme--blue & {
		color: $color_blue;
	}
	.theme--red & {
		color: $color_red;
	}
	.theme--orange & {
		color: $color_orange;
	}
	.theme--green & {
		color: $color_green;
	}
}

a,
button,
input:focus,
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea:focus,
.button {
	// transition: all 0.2s ease-out;
	transition: color 0.2s ease-out, background-color 0.2s ease-out;
}

::selection {
	// text-shadow: none;
}

a {

	&:hover,
	&:focus,
	&:active {

	}
}

p {
	padding: 0;
	margin: 0 0 $spacing_line;
	//max-width: 1100px;
	&:last-child {
		margin-bottom: 0;
	}
}

pre,
code {
	background: #eee;
	padding: 0.5em;
}

code {
	word-break: break-word;
	overflow: hidden;
}

iframe {
	border: none;
}

.uppercase {
	text-transform: uppercase;
}

.no-br {
	white-space: nowrap;
}
