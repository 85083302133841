button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.button {
	width: auto;
	line-height: 1;
	cursor: pointer;
	border: none;
	outline: none;
	padding: 0;
	background: none;
}

.cta {
	font-size: 1.5625em;
	border: 0.2em solid $color_green;
	color: $color_green;
	padding: 0.45em 1em 0.5em;
	text-align: center;
	text-transform: uppercase;
	border-radius: 15px;
	font-family: $sans;
	font-weight: 500;
	transition: all 0.2s ease-in-out;
	&:not(:last-child){
		margin-right: 1em;
		margin-bottom: 1em;
	}
	.theme--blue & {
		color: $color_blue;
		border-color: $color_blue;
	}
	.theme--red & {
		color: $color_red;
		border-color: $color_red;
	}
	.theme--orange & {
		color: $color_orange;
		border-color: $color_orange;
	}
	.theme--green & {
		color: $color_green;
		border-color: $color_green;
	}
	&:after {
		@include icon(arrow-right);
		font-size: 0.76em;
		display: inline-block;
		margin-left: 0.3em;
		// line-height: 1.75;
	}
	&--login {
		min-width: 8em;
	}
	&--secondary {
		color: $color_gray_dark !important;
		border-color: $color_gray_dark !important;
		&:after {
			content: none;
		}
		&:hover,
		&:focus,
		&:active {
			color: $color_gray !important;
			border-color: $color_gray !important;
		}
	}
	&--blue {
		color: $color_white !important;
		background-color: $color_blue;
		border-color: $color_blue;
		&:hover,
		&:focus,
		&:active {
			color: $color_blue !important;
			background-color: $color_white;
		}
	}
	&:hover,
	&:focus,
	&:active {
		border-color: $color_blue;
		color: $color_blue;
	}
}

.text-cta {
	font-size: 1.125em;
	font-weight: 500;
	color: $color_gray_dark;
	&:after {
		@include icon(arrow-right);
		font-size: 0.76em;
		display: inline-block;
		margin-left: 0.3em;
	}
	&:hover {
		.theme--blue & {
			color: $color_blue;
		}
		.theme--red & {
			color: $color_red;
		}
		.theme--orange & {
			color: $color_orange;
		}
		.theme--green & {
			color: $color_green;
		}
	}
}
