.sidebar {
	background-color: $color_gray_medium;
	height: 100%;
	.primary-nav {
		&__link {
			display: block;
			text-decoration: none;
			color: $color_black;
			padding-left: 1.3125em;
			height: 3.125rem;
			line-height: 3.125rem;
			position: relative;
			z-index: 1;
			transition: 0.15s 0.15s ease-out;
		}
		&__item {
			position: relative;
			font-size: 1.3125em;
			&--sub-item {
				font-size: 0.9em;
				.primary-nav {
					&__link {
						padding-left: 2em;
						border-top: 1px solid rgba($color_white, 0.5);
						height: auto;
						&:hover {
							background-color: rgba($color_white, 0.25);
						}
					}
				}
			}
			&:before {
				content: "";
				position: absolute;
				width: 0.667em;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 1;
				transition: 0.15s 0.15s ease-out;
			}
			&--current,
			&:hover {
				.primary-nav__link {
					color: $color_white;
					transition: 0.15s ease-out;
				}
				&:before {
					width: 100% !important;
					transition: 0.15s ease-out;
				}
			}
			&--blue {
				&:before {
					background-color: $color_blue;
				}
			}
			&--yellow {
				.primary-nav__link {
					color: $color_black !important;
				}
				&:before {
					background-color: $color_yellow;
				}
			}
			&--red {
				&:before {
					background-color: $color_red;
				}
			}
			&--orange {
				&:before {
					background-color: $color_orange;
				}
			}
			&--green {
				&:before {
					background-color: $color_green;
				}
			}
			&--log-out {
				position: absolute;
				bottom: 0;
				width: 100%;
				.primary-nav__link {
					background-color: $color_gray_dark;
					color: $color_white;
					text-align: center;
					padding: 1.5em 2em 1.5em 0.6904em;
					text-transform: uppercase;
					position: relative;
					height: auto;
					font-weight: 500;
					&:after {
						margin-left: 0.5em;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
					&:hover {
						background-color: $color_white;
						color: $color_gray_dark;
					}
				}
			}
		}
		&__sub-menu {
			display: none;
		}
	}
	@include breakpoint(max-width $break_large) {
		.primary-nav {
			&__link {
				padding-left: 1em;
			}
			&__item {
				font-size: 1em;
				line-height: 1.7;
				&:before {
					width: 0.4em;
				}
				&--sub-item {
					.primary-nav {
						&__link {
							padding-left: 1.3125em;
						}
					}
				}
			}
		}
	}
	@include breakpoint(max-width $break_medium) {
		background-color: $color_white;
		.primary-nav {
			display: none;
		}
	}
}

.mm-menu {
	background-color: $color_gray_medium;
	.arrow-right-icon {
		background-color: $color_gray_dark;
		color: $color_white !important;
		font-weight: 500;
		position: relative;
		text-transform: uppercase;
		// padding: 2em;
		&:after {
			margin-left: 0.5em;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.primary-nav__item--log-out {
		display: none;
	}
}

.menu-btn {
	position: absolute;
	right: $site_padding;
	top: 50%;
	transform: translateY(-50%);
	display: none;
	height: 25px;
	width: 32px;
	cursor: pointer;
	span, &:before, &:after {
		position: absolute;
		display: block;
		width: 32px;
		height: 5px;
		background-color: $color_gray_dark;
		left: 50%;
		top: 10px;
		margin: 0 0 0 -16px;
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}
	&:before {
		content: "";
		transform: translate(0, -10px);
	}
	&:after {
		content: "";
		transform: translate(0, 10px);
	}
	.mm-opened & {
		span, &:before, &:after {
			background-color: $color_gray_dark;
		}
		span {
			opacity: 0;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
	@include breakpoint(max-width $break_medium) {
		display: initial;
	}
}
