@mixin input_styles() {
	max-width: 100%;
	width: 100%;
	padding: 0.5em;
	border: 2px solid $color_gray_medium;
	// border-radius: 10px;
	border-radius: 15px;
	font-size: 1em;
	transition: border-color 0.2s ease-in-out;
	background-color: $color_white;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="file"],
textarea,
select,
.select-wrap,
.select2-container--default .select2-selection--single {
	@include input_styles;
	height: 3.125em;
	&:focus {
		outline: none;
		border-color: $color_blue;
		.theme--blue & {
			border-color: $color_blue;
		}
		.theme--red & {
			border-color: $color_red;
		}
		.theme--orange & {
			border-color: $color_orange;
		}
		.theme--green & {
			border-color: $color_green;
		}
	}
	.item-table & {
		border-radius: 0;
		border: none;
		min-width: 100%;
		background-color: transparent;
	}
}

textarea {
	@include input_styles;
	min-height: 11.25rem;
	max-height: 11.25rem;
	resize: none;
	vertical-align: top;
	&.short {
		min-height: 3.125rem;
		max-height: 3.125rem;
	}
	.item-table & {
		border-radius: 0;
		border: none;
	}
}

.placeholder {
	text-overflow: ellipsis;
	color: #CCC;
}

::placeholder {
	text-overflow: ellipsis;
	color: #CCC;
}

input[type="search"] {
	box-sizing: border-box; // Overrides normalize.css
}

.styled-select {
	display: none;
}

.select-wrap {
	position: relative;
	line-height: 1;
	padding-right: 3em;
	&.placeholder {
	}
	&:before {
		@include icon(arrow-down);
		position: absolute;
		right: 1em;
		top: 50%;
		transform: translateY(-50%);
		transition: all .25s ease-in;
		color: $color_gray_dark;
	}
	select {
		cursor: pointer;
		-webkit-appearance: none;
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		top: 0;
		left: 0;
		transition: all .25s ease-in-out;
	}
	ins {
		text-decoration: none;
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		line-height: 2.1;
	}
	&:hover:before {
		color: $color_blue;
	}
}

.checkbox-wrap,
.radio-wrap {
	position: relative;
	display: inline-block;
	height: 1.3333em;
	width: 1.3333em;
	border: 2px solid $color_gray_medium;
	vertical-align: middle;
	transition: all .25s ease-in-out;
	cursor: pointer;
	background: #FFF;
	margin-right: 0.1875em;
	border-radius: 4px;
	&.checked {
		background: $color_secondary;
		border: 2px solid $color_gray;
	}
	input {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		left: 0;
		top: 0;
	}
}


.checkbox-wrap,
.radio-wrap,
.checkgroup-wrap {
	+ span {
		cursor: pointer;
		display: inline-block;
		vertical-align: top;
		// max-width: calc(100% - 2em);
		margin-top: 0.15em;
		transition: inherit;
		&:hover,
		&:focus,
		&:active {
			// color: $color_blue;
		}
	}
}

.checkbox-wrap {
	&:before {
		content: "";
		position: absolute;
		width: 45%;
		height: 85%;
		left: 30%;
		top: 0;
		overflow: hidden;
		transition: all .25s ease-in-out;
		opacity: 0;
		border-bottom: 3px solid #000000;
		border-right: 3px solid #000000;
		transform: rotate(45deg);
	}
	&.checked {
		&:before {
			opacity: 1;
		}
	}
}

.checkbox {
	margin-top: 1em;
	margin-left: 1em;
	margin-right: 1em;
	display: inline-block;
}

.radio-wrap {
	border-radius: 50%;
	&:before {
		content: "";
		border-radius: 50%;
		padding: 1px;
		position: absolute;
		background: #FFF;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		color: #FFF;
		overflow: hidden;
		transition: all .25s ease-in-out;
		border: 0px solid #FFF;
	}
	&.checked {
		&:before {
			border: 5px solid $color_secondary;
		}
	}
}

.mvpos-form {
	&__row {
		> * {
			margin-bottom: $site_padding * 1.5;
		}
		&:last-child {
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.sale-change {
	span {
		line-height: 3.375rem;
		// padding-left: 0.5rem;
		// font-size: 3.375em;

		font-size: 2.625em;
		&:before {
			content: "$"
		}
	}
	@include breakpoint(1025px 1400px) {
		// font-size: 1.05vw;
	}
	@include breakpoint(769px 780px) {
		// font-size: 2vw;
	}
	@include breakpoint(321px 525px) {
		// font-size: 2.4vw;
	}
	@include breakpoint(max-width $break_phone_large) {
		// font-size: 0.555em;
	}
	@include breakpoint(max-width 320px) {
		// font-size: .4815em;
	}
}

.mvpos-form {
	margin-bottom: 4em;
	&__error {
		color: #F00;
		// display: block;
		font-size: 0.9375em;
		padding: 0.533em 0 0 0.533em;
		display: none;
	}
	&__row {
		&:not(.row) {
			@include clearfix;
			@include container;
			// &:not(:last-child) {
			// 	margin-bottom: $site_padding * 1.5;
			// }
		}
		&--no-mar {
			margin-bottom: 0 !important;
		}
	}
	&__reveal {
		display: none;
		margin-top: $site_padding * 1.5;
	}
	&__field {
		&--no-mar {
			margin-bottom: 0 !important;
		}
		&--center {
			text-align: center;
		}
		&--col {
			&:last-child {
				margin-right: 0;
				// @include last;
			}
		}
		&--half {
			@include span(6);
		}
		&--third,
		&--change {
			@include span(4);
		}
		&--fourth,
		&--method,
		&--tender {
			@include span(3);
		}
		&--sixth,
		&--receipt {
			@include span(2);
		}
		&--icon {
			position: relative;
			> * {
				padding-left: 3.5em !important;
			}
			&:before {
				position: absolute;
				font-size: 1.8em;
				left: 0.5em;
				top: 50%;
				transform: translateY(-50%);
				color: #8C8C8C;
			}
		}
		&--checkbox {
			// text-align: center;
		}
		@include breakpoint(max-width $break_phone_large) {
			&--method,
			&--tender,
			&--receipt,
			&--change, {
				@include span(3 of 6);
				&:nth-child(2n) {
					@include last;
				}
				&:not(:nth-child(n+2)) {
					margin-bottom: $site_padding * 1.5;
				}
			}
		}
		@include breakpoint(max-width $break_phone) {
			&--fourth {
				@include span(3 of 6);
				&:nth-child(2n) {
					@include last;
				}
				&:not(:nth-child(n+2)) {
					margin-bottom: $site_padding * 1.5;
				}
			}
			&--half,
			&--third {
				@include span(full);
				&:not(:last-child) {
					margin-bottom: $site_padding * 1.5;
				}
			}
		}
	}
	&__label {
		font-size: 0.9375em;
		padding: 0 0.533em 0.533em;
		// padding: 0 0 0.25em;
		font-weight: 400;
		display: block;
		color: $color_gray_dark;
	}
	&--complete {
		margin-bottom: 0;
		.cta {
			width: 100%;
		}
		.sale-change {
			span {
				line-height: 3.375rem;
				padding-left: 0.5rem;
				// font-size: 3.375em;

				font-size: 2.625em;
				&:before {
					content: "$"
				}
			}
			@include breakpoint(1025px 1400px) {
				// font-size: 1.05vw;
			}
			@include breakpoint(769px 780px) {
				// font-size: 2vw;
			}
			@include breakpoint(321px 525px) {
				// font-size: 2.4vw;
			}
			@include breakpoint(max-width $break_phone_large) {
				// font-size: 0.555em;
			}
			@include breakpoint(max-width 320px) {
				// font-size: .4815em;
			}
		}
	}
	&--vendor-info {
		.mvpos-form {
			&__field {
				&--title {
					position: relative;
					padding-right: 10em;
				}
			}
		}
		.text-cta {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		@include breakpoint(max-width $break_phone) {
			.mvpos-form {
				&__field {
					&--title {
						padding-right: 0;
					}
				}
			}
			.text-cta {
				position: relative;
				right: auto;
				top: auto;
				transform: none;
				margin-top: 1em;
			}
		}
	}
}
