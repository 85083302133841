@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/fonts/icomoon.eot?-f1kwr1');
	src:url('../fonts/icomoon/fonts/icomoon.eot?#iefix-f1kwr1') format('embedded-opentype'),
		url('../fonts/icomoon/fonts/icomoon.woff?-f1kwr1') format('woff'),
		url('../fonts/icomoon/fonts/icomoon.ttf?-f1kwr1') format('truetype'),
		url('../fonts/icomoon/fonts/icomoon.svg?-f1kwr1#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icon-params() {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	display: inline-block;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$icons: (
	user: "\e900",
	key: "\e901",
	arrow-up: "\e902",
	arrow-right: "\e903",
	arrow-down: "\e904",
	arrow-left: "\e905",
	remove: "\e906",
	minus: "\e907",
	plus: "\e908",
	settings: "\e909",
);

@function icon-content($icon) {
	@if map-has-key($icons, $icon) {
		@return map-get($icons, $icon);
	}

	@warn "Unknown `#{$icon}` in $icons.";
	@return null;
}

@mixin icon($icon: 'arrow') {
	content: icon-content($icon);
	@include icon-params();
}

[class^="icon-"]:not(.acf-icon),
[class*="icon-"]:not(.acf-icon) {
	&:before {
		@include icon-params();
	}
}

[class$="-icon"]:not(.acf-icon),
[class*="-icon"]:not(.acf-icon) {
	&:after {
		@include icon-params();
	}
}

@each $key, $value in $icons {
	.icon-#{$key} {
		&:before {
			content: $value;
		}
	}
	.#{$key}-icon {
		&:after {
			content: $value;
		}
	}
}
