.menu-primary-menu-container {
	@include clearfix;
	@include unstyled-list;
	width: 100%;

	.menu-item {
		position: relative;
		display: inline-block;
		text-align: left;

		&:hover,
		&:focus,
		&:active,
		&.sfHover {

			> .sub-menu {
				left: 0;
				opacity: 1;
				transition: opacity 0.2s ease-in-out;
				display: block;
			}
		}
	}

	a {
		position: relative;
		display: block;
	}

	a:hover,
	a:focus,
	a:active,
	.current-menu-item > a,
	.sub-menu .current-menu-item > a:hover {

	}

	.sub-menu .current-menu-item > a {

	}

	> .menu-item > a {

	}

	.touch & {
		.sub-menu {
			display: none;
		}
	}

	.sub-menu {
		position: absolute;
		top: 100%;
		left: -9999px;
		z-index: 99;
		opacity: 0;
		background-color: #DDD;
		transition: opacity 0.2s ease-in-out, left 0 linear 0.2s;

		.sub-menu {
			top: 0;
			background-color: #DDD;
		}
		.menu-item {
			position: relative;
			display: block;
			text-align: left;
			transition: all 0.2s ease-in-out;
			&:hover,
			&:focus,
			&:active,
			&.sfHover {
				background: #FFF;
				> .sub-menu {
					left: 100%;
				}
			}
		}
	}

	> .first {

		> a {

		}
	}

	> .last {

		> a {

		}
	}
}
