/*********************
SIZES
*********************/
$site_width:		1400px; // Useful for fixed width sites, as well as for limiting the max-width of fluid sites
$site_padding:		1.25rem;
$spacing_line:		2em;
$primary_sidebar_width: 16em;
$primary_sidebar_width_small: 7.1em;


/*********************
Media Queries
*********************/
$break_full: 			$site_width;
$break_xlarge:			89.9375em; // 1439px
$break_large:			75em; // 1200px
$break_medium:			1023px; // 1024px
$break_tablet_large:	60em; // 960px
$break_tablet:			48em; // 768px
$break_tablet_small:	40em; // 640px
$break_phone_large:		35.5em; // 568px
$break_phone:			30em; // 480px
$break_phone_small:		22.5em; // 360px
$break_phone_xsmall:	20em; // 320px
$break_wp_small:		782px;
$break_wp_xsmall:		600px;
$hidpi:					min-resolution 1.5dppx;

/*
 * Example: http://breakpoint-sass.com/
 * @include breakpoint($break_large) { content: 'media breakpoint at 1024px' }
 */


/*********************
COLORS
*********************/
$color_white: #FFFFFF;
$color_black: #000000;
$color_green: #6AAE2D;
$color_red: #C66155;
$color_yellow: #EFEF0A;
$color_orange: #FFB026;
$color_blue: #00A8E0;
$color_gray_medium: #D9D9D9;
$color_gray_dark: #808080;
$color_gray: #AAAAAA;

$color_primary:	$color_blue;
$color_secondary: $color_gray_medium;


/*********************
Files Paths
*********************/
$image_path: '../images/';
$font_path: '../fonts/';


/*********************
Misc Vars
*********************/

/*********************
SOCIAL COLORS - http://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
*********************/
$twitter:			#00aced;
$facebook: 			#3b5998;
$linkedin:			#007bb6;
$google:			#dd4b39;
$youtube:			#bb0000;
$pinterest: 		#cb2027;
$tumblr:			#32506d;
$flickr:			#ff0084;
$instagram:			#517fa4;
$vimeo:				#aad450;
$foursquare: 		#0072b1;
$rss:				#ff6600;
