html, body, .site-container {
	min-width: 320px;
	// height: 100%;
	overflow-x: hidden;
}

body {
	background-color: $color_white;
}

.clearfix,
.site-container,
.site-header,
.site-inner,
.site-footer,
.wrap {
	@include clearfix;
}

.wrap,
.wrapper {
 	@include wrap;
	max-width: none;
}

.site-inner {
}

.sidebar {
	width: $primary_sidebar_width;
	position: fixed;
	+ .content {
		padding-left: $primary_sidebar_width;
		padding-top: 12.375em;
	}
	@include breakpoint(max-width $break_large) {
		width: $primary_sidebar_width_small;
		+ .content {
			padding-top: 11.375em;
			padding-left: $primary_sidebar_width_small;
		}
	}
	@include breakpoint(max-width $break_medium) {
		position: relative;
		width: 100%;
		+ .content {
			padding-left: 0;
			padding-top: 0;
		}
	}
}
