@charset "utf-8";
@import "../bower_components/breakpoint-sass/stylesheets/breakpoint";
@import "../bower_components/susy/sass/susy";

$susy: (
    columns: 12,
    // debug: (
    //     image: show,
    //     output: overlay,
    //     toggle: top right
    // )
);

*, *:before, *:after {
	box-sizing: border-box;
}

// Variables, Clearfix, Toolkit, Mixins
@import "partials/setup/reset";
@import "partials/setup/variables";
@import "partials/setup/toolkit";

// Sitewide Styles
//@import "partials/global/grid";
@import "partials/global/grid";
@import "partials/global/layout";
@import "partials/global/icons";
@import "partials/global/typography";
@import "partials/global/media";
@import "partials/global/forms";
@import "partials/global/ctas";

// Module Styles
@import "partials/modules/header";
@import "partials/modules/navigation";
@import "partials/modules/content-modules";
@import "partials/modules/sidebars";
@import "partials/modules/footer";

// Templates
@import "partials/views/views";

// Print
@import "partials/fallbacks/print";

// Shame - For new style snippets that haven't been sorted and commented yet
@import "partials/fallbacks/shame";
