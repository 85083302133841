$imageMargin: 		1.5em;

embed,
iframe,
img,
object,
video {
	max-width: 100%;
}

img {
	width: auto; /* IE8 */
	height: auto;
}
