/************************************************************************************************************************
 * Mixins
 ************************************************************************************************************************/
@mixin wrap {
	// margin: 0 auto;
	width: 100%;
	max-width: $site_width;
	padding-left: $site_padding;
	padding-right: $site_padding;
}

// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
@mixin image-replacement {
	overflow: hidden;
	text-indent: -9999px;
	white-space: nowrap;
}

// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visuallyhidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	border: 0;
}

@mixin no-js {

	.hide-no-js {
		display: none !important;
	}
}

// http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix {
	zoom: 1;			// For IE

	&:before,
	&:after {
		display: table;
		content: "";
	}

	&:after {
		clear: both;
	}
}

@mixin unstyled-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin justify-grid {
	text-align: justify;
	text-justify: distribute-all-lines;
	font-size: 0;
	> * {
		display: inline-block;
		font-size: 1rem;
		text-align: left;
		vertical-align: top;
	}
	&:after {
		content: "";
		width: 100%;
		display: inline-block;
		vertical-align: top;
	}
}

// Render fonts properly
@mixin font_smooth($font: $font_display) {
	font-family: $font;

	[data-useragent*="macintosh"] & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

// arrows
//
// $direction: top, left, right, bottom, top-left, top-right, bottom-left, bottom-right
// $color: hex, rgb or rbga
// $size: px or em
// @example
// .element{
//     @include arrow(top, #000, 50px);
// }
@mixin arrow($direction, $color, $size, $flatSide: $size * 2){
    display: block;
    height: 0;
    width: 0;

    $flatSide: $flatSide/2;

    @if $direction == 'top' {
        border-left: $flatSide solid transparent;
    	border-right: $flatSide solid transparent;
    	border-bottom: $size solid $color;
    } @else if $direction == 'right' {
        border-top: $flatSide solid transparent;
        border-bottom: $flatSide solid transparent;
    	border-left: $size solid $color;
    } @else if $direction == 'bottom' {
        border-top: $size solid $color;
        border-right: $flatSide solid transparent;
        border-left: $flatSide solid transparent;
    } @else if $direction == 'left' {
        border-top: $flatSide solid transparent;
    	border-right: $size solid $color;
    	border-bottom: $flatSide solid transparent;
    } @else if $direction == 'top-left' {
        border-top: $size solid $color;
        border-right: $size solid transparent;
    } @else if $direction == 'top-right' {
        border-top: $size solid $color;
        border-left: $size solid transparent;
    } @else if $direction == 'bottom-left' {
        border-bottom: $size solid $color;
        border-right: $size solid transparent;
    } @else if $direction == 'bottom-right' {
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
    }
}

// Webkit-style focus
@mixin tab-focus() {
  // Default
  outline: thin dotted $primary_color;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}


/************************************************************************************************************************
 * Functions
 ************************************************************************************************************************/
@function calc_percent($target, $container) {
	@return ($target / $container) * 100%;
}

@function clear_units($value) {
	@if type-of($value) == "number" {
		@if (unitless($value)) { @return $value; }
		@else if unit($value) == "em"{ @return $value / 1em; }
		@else if unit($value) == "px" { @return $value / 1px; }
		@else if unit($value) == "pt" { @return $value / 1pt; }
	} @else {
		@warn "Not a number value: #{$value}";
		@return $value;
	}
}

@function calc_em($target, $context:$base_font_size) {
	$target: clear_units($target);
	$context: clear_units($context);
	@return #{$target / $context}em
}

@function calc_rem($target, $context:$base_font_size) {
	$target: clear_units($target);
	$context: clear_units($context);
	@return #{$target / $context}rem
}

@function image_url($filename, $filepath: $image_path) {
	@return url($filepath + $filename);
}
