.logo {
	max-width: 100%;
	display: inline-block;
	width: $primary_sidebar_width;
	background: #FFF;
	vertical-align: middle;
	transition: none;
	h1 {
		background-image: image_url('logo.svg');
		background-repeat: no-repeat;
		background-size: cover;
		margin: 0;
		@include image-replacement;
	}
	.login-form & {
		width: 27.5em;
		margin: 0 auto 3em;
		h1 {
			padding-top: 41%;
			font-size: 0;
		}
	}
	.sidebar & {
		padding: 1.25em;
		h1 {
			height: 5.5em;
		}
		@include breakpoint(max-width $break_large) {
			padding: 0.75em;
		}
		@include breakpoint(max-width $break_medium) {
			max-width: 45%;
			h1 {
				background-size: contain;
				background-position: center center;
				padding-top: 41%;
				font-size: 0;
			}
		}
	}
}

.home {
}

.login-form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 27.5em;
	width: calc(100% - 2em);
	.mvpos-form__field--submit {
		text-align: center;
	}
}

.time-bar {
	min-height: 3.125em;
	background: $color_gray_dark;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: $color_gray_dark;
		left: -1px;
		top: 0;
		transform: translateX(100%);

	}
	&__date {
		color: $color_white;
		font-size: 1.3125em;
		float: right;
		line-height: 2.38em;
	}
	&__count {
		color: $color_white;
		font-size: 1.3125em;
		float: left;
		line-height: 2.38em;
	}
	&__select {
		float: right;
		margin-top: 0.55em;
		margin-left: $site_padding;
		width: 200px;
		.select2-container--default .select2-selection--single {
			height: 2em;
			border-radius: 10px;
			padding-top: 0.45em;
			padding-bottom: 0.45em;
		}
		.select2-container .select2-selection--single .select2-selection__rendered {
			line-height: 1;
		}
	}
}

.item-table {
	width: 100%;
	border-collapse: separate;
	border-radius: 15px;
	margin-bottom: $site_padding;
	tr {
		&.even {
			background: #eee;
			+ tr.no-padding {
				background: #eee;
			}
		}
	}
	tr:first-child {
		th:first-child {
			border-top-left-radius: 15px;
		}
		th:last-child {
			border-top-right-radius: 15px;
		}
	}
	th {
		background: $color_white;
		color: $color_gray_dark;
		font-size: 1.125em;
		font-weight: 400;
		padding: 0.5rem;
		white-space: nowrap;
		height: 3.125rem;
		vertical-align: middle;
		.theme--blue & {
  			color: $color_blue;
	  	}
	  	.theme--red & {
	  		color: $color_red;
	  	}
	  	.theme--orange & {
	  		color: $color_orange;
	  	}
	  	.theme--green & {
	  		color: $color_green;
	  	}
	}
	td, th {
	  border: 1px solid $color_gray_medium;
	  text-align: left;
	}
	td {
		vertical-align: top;
		&[data-detail="Item #"] {
			max-width: 8em;
		}
		&[data-detail="Vendor"],
		&[data-detail="Item Name"] {
			min-width: 9.5em;
			max-width: 9.5em;
		}
		&[data-detail="QTY"] {
			max-width: 4.5em;
		}
		&[data-detail="Discount"] {
			max-width: 5em;
		}
		&[data-detail="Price"],
		&[data-detail="Subtotal"],
		&[data-detail="Total"] {
			width: 5.6em;
		}
		&[data-detail="Taxable"] {
			vertical-align: middle;
			text-align: center;
		}
		&[data-detail="Remove"] {
			vertical-align: middle;
			text-align: center;
			min-width: 3em;
			text-align: center;
			.icon-remove {
				font-size: 1.1em;
				color: $color_red;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
	tfoot {
		tr {
			button {
				width: 100%;
				border-radius: 0 0 15px 15px;
			}
		}
		td {
			background-color: $color_blue;
			border: none;
			padding: 0;
			height: 1.5em;
			// border-radius: 0 0 15px 15px;
			&:first-child {
				border-bottom-left-radius: 15px;
			}
			&:last-child {
				border-bottom-right-radius: 15px;
			}
			.theme--blue & {
				background-color: $color_blue;
				color: $color_white;
				height: 3.125em;
			}
			.theme--red & {
				background-color: $color_red;
				color: $color_white;
			}
			.theme--orange & {
				background-color: $color_orange;
				color: $color_white;
			}
			.theme--green & {
				background-color: $color_green;
				color: $color_white;
			}
		}
	}
	&--dtable {
		table {
			width: 100%;
			th {
				font-size: 1em;
			}
			tr {
				&:nth-child(even) {
					background: #eee;
				}

			}
		}
		tr {
			.btn-toggle-sale-breakdown {
				&__container {
					text-align: center;
				}
				&:before {
					@include icon(plus);
					font-size: 1.7em;
					color: $color_green;
				}
				&:hover {
					opacity: 0.7;
				}
			}
			&.shown {
				.btn-toggle-sale-breakdown {
					&:before {
						@include icon(minus);
					}
				}
			}
		}
		td {
			&.no-padding {
				padding: 0;
				.slider {
					padding: 15px;
				}
			}
		}
	}
	@include breakpoint(max-width $break_full) {
		&--dtable {
			font-size: 0.75em;
		}
	}
	@include breakpoint(max-width $break_medium) {
		&--responsive {
			display: block;
			thead, tbody, tfoot, th, td, tr {
				display: block;
			}
			thead tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}

			tr {
				border: 1px solid $color_gray_medium;
			}

			td {
				/* Behave  like a "row" */
				border: none;
				border-bottom: 1px solid $color_gray_medium;
				position: relative;
				padding-left: 7.25em;
				min-height: 3.125em;
				max-width: none !important;
				width: auto !important;
				&[data-detail="Remove"] {
					padding-top: 1em;
					padding-bottom: 1em;
				}
				&:before {
					content: attr(data-detail);
					/* Now like a table header */
					position: absolute;
					top: 0;
					left: 0;
					width: 7.25em;
					white-space: nowrap;
					height: 100%;
					border-right: 1px solid $color_gray_medium;
					padding: 1em $site_padding;
					text-align: left;
				}
			}
			tfoot {
				tr {
					border: none;
				}
				td:before {
					border: none;
				}
			}
		}
	}
}
.select2-container {
	width: calc(100% + 1px) !important;
	// margin-left: -1px;
}
.select2-dropdown {
	border-color: $color_gray_medium;
}
.select2-search--dropdown .select2-search__field {
	height: 2em;
	border-radius: 8px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100%;
	top: 0;
	width: 1.5em;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 0;
	line-height: 2;
}
.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: $color_gray_medium;
	color: $color_black;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $color_blue;
	color: $color_white;
}
.select2-container--open .select2-dropdown--below {
	border-radius: 0 0 9px 9px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	overflow: hidden;
}

.order-details-block {
	width: calc(50% - #{$site_padding} / 2);
	margin-bottom: $site_padding;
	border: 1px solid $color_gray_medium;
	border-radius: 15px;
	padding-bottom: $site_padding;
	h3 {
		padding: $site_padding;
	}
	&__header {
		position: relative;;
		.text-cta {
			position: absolute;
			right: $site_padding;
			top: 50%;
			transform: translateY(-50%);
			.touchevents & {
				display: none !important;
			}
		}
	}
	&--info {
		float: right;
		h3 {
			border-bottom: 1px solid $color_gray_medium;
			margin-bottom: $site_padding;
		}
		button {
			width: 100%;
		}
	}
	&--totals {
		float: left;
		padding-bottom: 0;
	}
	&--day-report {
		width: 100%;
		padding-bottom: 0;
		margin-bottom: 4em;
	}
	&--complete {
		float: right;
		h3 {
			border-bottom: 1px solid $color_gray_medium;
			margin-bottom: $site_padding;
		}
	}
	@include breakpoint(max-width $break_medium) {
		width: 100%;
		float: none;
	}
}

.totals-table {
	width: 100%;
	border-collapse: separate;
	td, th {
		border: 1px solid $color_gray_medium;
		vertical-align: middle;
		padding: $site_padding;

		font-size: 2em;
		// height: 9.811rem;
	}
	th {
		// font-size: 2em;
		font-size: 1.8em;
		text-align: left;
	}
	td {
		// font-size: 3.375em;
		text-align: right;
	}
	tfoot {
		color: $color_white;
		background-color: $color_blue;
		td, th {
			font-size: 2.625em;
		}
		th {
			text-transform: uppercase;
			border-bottom-left-radius: 15px;
		}
		td {
			border-bottom-right-radius: 15px;
		}
		.theme--blue & {
			background-color: $color_blue;
		}
		.theme--red & {
			background-color: $color_red;
		}
		.theme--orange & {
			background-color: $color_orange;
		}
		.theme--green & {
			background-color: $color_green;
		}
	}
	@include breakpoint(1025px 1400px) {
		// font-size: 1.05vw;
	}
	@include breakpoint(769px 780px) {
		// font-size: 2vw;
	}
	@include breakpoint(max-width 525px) {
		td, th {
			// height: 5rem;
		}
	}
	@include breakpoint(321px 525px) {
		// font-size: 2.4vw;
	}
	@include breakpoint(max-width $break_phone_large) {
		font-size: 0.555em;
	}
	@include breakpoint(max-width 320px) {
		// font-size: .4815em;
	}
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
    margin-bottom: 0;
}
.tt {
	&-hint {
		display: block;
		width: 100%;
		height: 38px;
		padding: 8px 12px;
		font-size: 14px;
		line-height: 1.428571429;
		color: #999;
		vertical-align: middle;
		background-color: #ffffff;
		border-radius: 4px;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	}
	&-menu {
		min-width: calc(100% + 2px);
		margin-left: -1px;
		padding: 0;
		background-color: #ffffff;
		border-radius: 0 0 9px 9px;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		border-top: none;
		overflow: hidden;
		border: 1px solid $color_gray_medium;
		border-top: none;
	}
	&-suggestion {
		display: block;
		padding: 0.5em;
		margin: 0;
		cursor: pointer;
		&:hover {
			color: #fff;
			background-color: $color_blue;
		}
		&.tt-is-under-cursor a {
			color: #fff;
		}
	}
}

table.dataTable {
	margin-top: $site_padding;
	float: left;
	width: 100%;
	thead th,
	tfoot th {
		font-weight: 400;
	}

	thead th,
	thead td {
		border-color: $color_gray_medium;
	}

	tfoot th,
	tfoot td {
		border-color: $color_gray_medium;
	}

	&.no-footer {
		border-bottom: none;
	}
	@include breakpoint(max-width $break_full) {
		thead,
		tbody,
		tfoot {
			th, td {
				padding: 8px 16px 8px 8px;
			}
		}
	}
}

.dataTables_wrapper {
	margin-bottom: 4em;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	.dataTables_filter input,
	.dataTables_length select {
		width: auto;
		height: 2em;
	}
}
