.page-header {
	margin-bottom: $site_padding;
	position: fixed;
	top: 0;
	width: calc(100% - #{$primary_sidebar_width});
	z-index: 1;
	background-color: $color_blue;
	.theme--blue & {
		background-color: $color_blue;
	}
	.theme--red & {
		background-color: $color_red;
	}
	.theme--orange & {
		background-color: $color_orange;
	}
	.theme--green & {
		background-color: $color_green;
	}
	.theme--gray & {
		background-color: $color_gray;
	}
	&__page {
		.wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	h2 {
		color: $color_white;
		font-size: 2em;
		display: inline-block;
		vertical-align: middle;
		line-height: 4;
		font-weight: 400;
	}
	&__settings {
		text-decoration: none;
		color: $color_white;
		// float: right;
		&:before {
			font-size: 1.25em;
			margin-right: 0.25em;
			position: relative;
			top: 0.15em;
		}
		&:hover {
			color: $color_gray_medium;
		}
		.settings & {
			display: none;
		}
	}
	@include breakpoint(max-width $break_large) {
		width: calc(100% - #{$primary_sidebar_width_small});
		h2 {
			line-height: 3.5;
		}
	}
	@include breakpoint(max-width $break_medium) {
		position: static;
		width: 100%;
		h2 {
			line-height: 2.5;
		}
	}
}
