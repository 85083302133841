@media print {

	* {
		background: transparent !important;
		color: black !important;
		text-shadow: none !important;
		filter: none !important;
	}

	a:link,
	a:visited {
		color: black !important;
		text-decoration: underline;
	}

	pre,
	blockquote {
		border: 1px solid black;
		page-break-inside: avoid;
	}

	@page {
		margin: 2cm 1.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 4;
		widows: 2;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	.menu,
	.widget-area,
	.sidebar,
	.footer-widgets,
	.site-footer {
		display: none !important;
	}

	.hide-print {
		display: none !important;
	}

	.title-area {
		float: none;
	}

	.site-header {

		.site-title {

			img {
				display: block;
				margin: 0 auto 1em;
			}
		}
	}

	.content {
		width: 100% !important;
	}

	.entry-title,
	.entry-content {

		a[href]:after {
			content: " (" attr(href) ")";
		}

		abbr[title]:after {
			content: " (" attr(title) ")";
		}

		a[href^="#"]:after,
		a[href^="javascript"]:after {
			content: "";
		}
	}
}