@charset "UTF-8";
@import 'https://fonts.googleapis.com/css?family=Rubik:300,400,500';
*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ v2.0 | 20110126 License: none (public domain) */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }

body { line-height: 1; }

ol, ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-spacing: 0; }

/*********************
SIZES
*********************/
/*********************
Media Queries
*********************/
/* Example: http://breakpoint-sass.com/ @include breakpoint($break_large) { content: 'media breakpoint at 1024px' } */
/*********************
COLORS
*********************/
/*********************
Files Paths
*********************/
/*********************
Misc Vars
*********************/
/*********************
SOCIAL COLORS - http://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
*********************/
/************************************************************************************************************************ Mixins */
/************************************************************************************************************************ Functions */
.container-fluid, .container { margin-right: auto; margin-left: auto; }

.container-fluid { padding-right: 2rem; padding-left: 2rem; }

.row { box-sizing: border-box; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-flex: 0; -ms-flex: 0 1 auto; flex: 0 1 auto; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-right: -0.5rem; margin-left: -0.5rem; }

.row.reverse { -webkit-box-orient: horizontal; -webkit-box-direction: reverse; -ms-flex-direction: row-reverse; flex-direction: row-reverse; }

.col.reverse { -webkit-box-orient: vertical; -webkit-box-direction: reverse; -ms-flex-direction: column-reverse; flex-direction: column-reverse; }

.col-xs-shrink, .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12 { box-sizing: border-box; -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; padding-right: 0.5rem; padding-left: 0.5rem; }

.col-xs-shrink { flex-grow: 0; flex-basis: auto; max-width: 100%; }

.col-xs { -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; -ms-flex-preferred-size: 0; flex-basis: 0; max-width: 100%; }

.col-xs-1 { -ms-flex-preferred-size: 8.33333333%; flex-basis: 8.33333333%; max-width: 8.33333333%; }

.col-xs-2 { -ms-flex-preferred-size: 16.66666667%; flex-basis: 16.66666667%; max-width: 16.66666667%; }

.col-xs-3 { -ms-flex-preferred-size: 25%; flex-basis: 25%; max-width: 25%; }

.col-xs-4 { -ms-flex-preferred-size: 33.33333333%; flex-basis: 33.33333333%; max-width: 33.33333333%; }

.col-xs-5 { -ms-flex-preferred-size: 41.66666667%; flex-basis: 41.66666667%; max-width: 41.66666667%; }

.col-xs-6 { -ms-flex-preferred-size: 50%; flex-basis: 50%; max-width: 50%; }

.col-xs-7 { -ms-flex-preferred-size: 58.33333333%; flex-basis: 58.33333333%; max-width: 58.33333333%; }

.col-xs-8 { -ms-flex-preferred-size: 66.66666667%; flex-basis: 66.66666667%; max-width: 66.66666667%; }

.col-xs-9 { -ms-flex-preferred-size: 75%; flex-basis: 75%; max-width: 75%; }

.col-xs-10 { -ms-flex-preferred-size: 83.33333333%; flex-basis: 83.33333333%; max-width: 83.33333333%; }

.col-xs-11 { -ms-flex-preferred-size: 91.66666667%; flex-basis: 91.66666667%; max-width: 91.66666667%; }

.col-xs-12 { -ms-flex-preferred-size: 100%; flex-basis: 100%; max-width: 100%; }

.col-xs-offset-0 { margin-left: 0; }

.col-xs-offset-1 { margin-left: 8.33333333%; }

.col-xs-offset-2 { margin-left: 16.66666667%; }

.col-xs-offset-3 { margin-left: 25%; }

.col-xs-offset-4 { margin-left: 33.33333333%; }

.col-xs-offset-5 { margin-left: 41.66666667%; }

.col-xs-offset-6 { margin-left: 50%; }

.col-xs-offset-7 { margin-left: 58.33333333%; }

.col-xs-offset-8 { margin-left: 66.66666667%; }

.col-xs-offset-9 { margin-left: 75%; }

.col-xs-offset-10 { margin-left: 83.33333333%; }

.col-xs-offset-11 { margin-left: 91.66666667%; }

.start-xs { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; text-align: start; }

.center-xs { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; text-align: center; }

.end-xs { -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; text-align: end; }

.top-xs { -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }

.middle-xs { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }

.bottom-xs { -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }

.around-xs { -ms-flex-pack: distribute; justify-content: space-around; }

.between-xs { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }

.first-xs { -webkit-box-ordinal-group: 0; -ms-flex-order: -1; order: -1; }

.last-xs { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; }

@media only screen and (min-width: 48em) { .container { width: 49rem; }
  .col-sm-shrink, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-offset-0, .col-sm-offset-1, .col-sm-offset-2, .col-sm-offset-3, .col-sm-offset-4, .col-sm-offset-5, .col-sm-offset-6, .col-sm-offset-7, .col-sm-offset-8, .col-sm-offset-9, .col-sm-offset-10, .col-sm-offset-11, .col-sm-offset-12 { box-sizing: border-box; -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; padding-right: 0.5rem; padding-left: 0.5rem; }
  .col-sm-shrink { flex-grow: 0; flex-basis: auto; max-width: 100%; }
  .col-sm { -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; -ms-flex-preferred-size: 0; flex-basis: 0; max-width: 100%; }
  .col-sm-1 { -ms-flex-preferred-size: 8.33333333%; flex-basis: 8.33333333%; max-width: 8.33333333%; }
  .col-sm-2 { -ms-flex-preferred-size: 16.66666667%; flex-basis: 16.66666667%; max-width: 16.66666667%; }
  .col-sm-3 { -ms-flex-preferred-size: 25%; flex-basis: 25%; max-width: 25%; }
  .col-sm-4 { -ms-flex-preferred-size: 33.33333333%; flex-basis: 33.33333333%; max-width: 33.33333333%; }
  .col-sm-5 { -ms-flex-preferred-size: 41.66666667%; flex-basis: 41.66666667%; max-width: 41.66666667%; }
  .col-sm-6 { -ms-flex-preferred-size: 50%; flex-basis: 50%; max-width: 50%; }
  .col-sm-7 { -ms-flex-preferred-size: 58.33333333%; flex-basis: 58.33333333%; max-width: 58.33333333%; }
  .col-sm-8 { -ms-flex-preferred-size: 66.66666667%; flex-basis: 66.66666667%; max-width: 66.66666667%; }
  .col-sm-9 { -ms-flex-preferred-size: 75%; flex-basis: 75%; max-width: 75%; }
  .col-sm-10 { -ms-flex-preferred-size: 83.33333333%; flex-basis: 83.33333333%; max-width: 83.33333333%; }
  .col-sm-11 { -ms-flex-preferred-size: 91.66666667%; flex-basis: 91.66666667%; max-width: 91.66666667%; }
  .col-sm-12 { -ms-flex-preferred-size: 100%; flex-basis: 100%; max-width: 100%; }
  .col-sm-offset-0 { margin-left: 0; }
  .col-sm-offset-1 { margin-left: 8.33333333%; }
  .col-sm-offset-2 { margin-left: 16.66666667%; }
  .col-sm-offset-3 { margin-left: 25%; }
  .col-sm-offset-4 { margin-left: 33.33333333%; }
  .col-sm-offset-5 { margin-left: 41.66666667%; }
  .col-sm-offset-6 { margin-left: 50%; }
  .col-sm-offset-7 { margin-left: 58.33333333%; }
  .col-sm-offset-8 { margin-left: 66.66666667%; }
  .col-sm-offset-9 { margin-left: 75%; }
  .col-sm-offset-10 { margin-left: 83.33333333%; }
  .col-sm-offset-11 { margin-left: 91.66666667%; }
  .start-sm { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; text-align: start; }
  .center-sm { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; text-align: center; }
  .end-sm { -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; text-align: end; }
  .top-sm { -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }
  .middle-sm { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  .bottom-sm { -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }
  .around-sm { -ms-flex-pack: distribute; justify-content: space-around; }
  .between-sm { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
  .first-sm { -webkit-box-ordinal-group: 0; -ms-flex-order: -1; order: -1; }
  .last-sm { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; } }

@media only screen and (min-width: 64em) { .container { width: 65rem; }
  .col-md-shrink, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-offset-0, .col-md-offset-1, .col-md-offset-2, .col-md-offset-3, .col-md-offset-4, .col-md-offset-5, .col-md-offset-6, .col-md-offset-7, .col-md-offset-8, .col-md-offset-9, .col-md-offset-10, .col-md-offset-11, .col-md-offset-12 { box-sizing: border-box; -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; padding-right: 0.5rem; padding-left: 0.5rem; }
  .col-md-shrink { flex-grow: 0; flex-basis: auto; max-width: 100%; }
  .col-md { -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; -ms-flex-preferred-size: 0; flex-basis: 0; max-width: 100%; }
  .col-md-1 { -ms-flex-preferred-size: 8.33333333%; flex-basis: 8.33333333%; max-width: 8.33333333%; }
  .col-md-2 { -ms-flex-preferred-size: 16.66666667%; flex-basis: 16.66666667%; max-width: 16.66666667%; }
  .col-md-3 { -ms-flex-preferred-size: 25%; flex-basis: 25%; max-width: 25%; }
  .col-md-4 { -ms-flex-preferred-size: 33.33333333%; flex-basis: 33.33333333%; max-width: 33.33333333%; }
  .col-md-5 { -ms-flex-preferred-size: 41.66666667%; flex-basis: 41.66666667%; max-width: 41.66666667%; }
  .col-md-6 { -ms-flex-preferred-size: 50%; flex-basis: 50%; max-width: 50%; }
  .col-md-7 { -ms-flex-preferred-size: 58.33333333%; flex-basis: 58.33333333%; max-width: 58.33333333%; }
  .col-md-8 { -ms-flex-preferred-size: 66.66666667%; flex-basis: 66.66666667%; max-width: 66.66666667%; }
  .col-md-9 { -ms-flex-preferred-size: 75%; flex-basis: 75%; max-width: 75%; }
  .col-md-10 { -ms-flex-preferred-size: 83.33333333%; flex-basis: 83.33333333%; max-width: 83.33333333%; }
  .col-md-11 { -ms-flex-preferred-size: 91.66666667%; flex-basis: 91.66666667%; max-width: 91.66666667%; }
  .col-md-12 { -ms-flex-preferred-size: 100%; flex-basis: 100%; max-width: 100%; }
  .col-md-offset-0 { margin-left: 0; }
  .col-md-offset-1 { margin-left: 8.33333333%; }
  .col-md-offset-2 { margin-left: 16.66666667%; }
  .col-md-offset-3 { margin-left: 25%; }
  .col-md-offset-4 { margin-left: 33.33333333%; }
  .col-md-offset-5 { margin-left: 41.66666667%; }
  .col-md-offset-6 { margin-left: 50%; }
  .col-md-offset-7 { margin-left: 58.33333333%; }
  .col-md-offset-8 { margin-left: 66.66666667%; }
  .col-md-offset-9 { margin-left: 75%; }
  .col-md-offset-10 { margin-left: 83.33333333%; }
  .col-md-offset-11 { margin-left: 91.66666667%; }
  .start-md { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; text-align: start; }
  .center-md { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; text-align: center; }
  .end-md { -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; text-align: end; }
  .top-md { -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }
  .middle-md { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  .bottom-md { -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }
  .around-md { -ms-flex-pack: distribute; justify-content: space-around; }
  .between-md { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
  .first-md { -webkit-box-ordinal-group: 0; -ms-flex-order: -1; order: -1; }
  .last-md { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; } }

@media only screen and (min-width: 75em) { .container { width: 76rem; }
  .col-lg-shrink, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-offset-0, .col-lg-offset-1, .col-lg-offset-2, .col-lg-offset-3, .col-lg-offset-4, .col-lg-offset-5, .col-lg-offset-6, .col-lg-offset-7, .col-lg-offset-8, .col-lg-offset-9, .col-lg-offset-10, .col-lg-offset-11, .col-lg-offset-12 { box-sizing: border-box; -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; padding-right: 0.5rem; padding-left: 0.5rem; }
  .col-lg-shrink { flex-grow: 0; flex-basis: auto; max-width: 100%; }
  .col-lg { -webkit-box-flex: 1; -ms-flex-positive: 1; flex-grow: 1; -ms-flex-preferred-size: 0; flex-basis: 0; max-width: 100%; }
  .col-lg-1 { -ms-flex-preferred-size: 8.33333333%; flex-basis: 8.33333333%; max-width: 8.33333333%; }
  .col-lg-2 { -ms-flex-preferred-size: 16.66666667%; flex-basis: 16.66666667%; max-width: 16.66666667%; }
  .col-lg-3 { -ms-flex-preferred-size: 25%; flex-basis: 25%; max-width: 25%; }
  .col-lg-4 { -ms-flex-preferred-size: 33.33333333%; flex-basis: 33.33333333%; max-width: 33.33333333%; }
  .col-lg-5 { -ms-flex-preferred-size: 41.66666667%; flex-basis: 41.66666667%; max-width: 41.66666667%; }
  .col-lg-6 { -ms-flex-preferred-size: 50%; flex-basis: 50%; max-width: 50%; }
  .col-lg-7 { -ms-flex-preferred-size: 58.33333333%; flex-basis: 58.33333333%; max-width: 58.33333333%; }
  .col-lg-8 { -ms-flex-preferred-size: 66.66666667%; flex-basis: 66.66666667%; max-width: 66.66666667%; }
  .col-lg-9 { -ms-flex-preferred-size: 75%; flex-basis: 75%; max-width: 75%; }
  .col-lg-10 { -ms-flex-preferred-size: 83.33333333%; flex-basis: 83.33333333%; max-width: 83.33333333%; }
  .col-lg-11 { -ms-flex-preferred-size: 91.66666667%; flex-basis: 91.66666667%; max-width: 91.66666667%; }
  .col-lg-12 { -ms-flex-preferred-size: 100%; flex-basis: 100%; max-width: 100%; }
  .col-lg-offset-0 { margin-left: 0; }
  .col-lg-offset-1 { margin-left: 8.33333333%; }
  .col-lg-offset-2 { margin-left: 16.66666667%; }
  .col-lg-offset-3 { margin-left: 25%; }
  .col-lg-offset-4 { margin-left: 33.33333333%; }
  .col-lg-offset-5 { margin-left: 41.66666667%; }
  .col-lg-offset-6 { margin-left: 50%; }
  .col-lg-offset-7 { margin-left: 58.33333333%; }
  .col-lg-offset-8 { margin-left: 66.66666667%; }
  .col-lg-offset-9 { margin-left: 75%; }
  .col-lg-offset-10 { margin-left: 83.33333333%; }
  .col-lg-offset-11 { margin-left: 91.66666667%; }
  .start-lg { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; text-align: start; }
  .center-lg { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; text-align: center; }
  .end-lg { -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; text-align: end; }
  .top-lg { -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }
  .middle-lg { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  .bottom-lg { -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }
  .around-lg { -ms-flex-pack: distribute; justify-content: space-around; }
  .between-lg { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
  .first-lg { -webkit-box-ordinal-group: 0; -ms-flex-order: -1; order: -1; }
  .last-lg { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; } }

html, body, .site-container { min-width: 320px; overflow-x: hidden; }

body { background-color: #FFFFFF; }

.clearfix, .site-container, .site-header, .site-inner, .site-footer, .wrap { zoom: 1; }

.clearfix:before, .clearfix:after, .site-container:before, .site-container:after, .site-header:before, .site-header:after, .site-inner:before, .site-inner:after, .site-footer:before, .site-footer:after, .wrap:before, .wrap:after { display: table; content: ""; }

.clearfix:after, .site-container:after, .site-header:after, .site-inner:after, .site-footer:after, .wrap:after { clear: both; }

.wrap, .wrapper { width: 100%; max-width: 1400px; padding-left: 1.25rem; padding-right: 1.25rem; max-width: none; }

.sidebar { width: 16em; position: fixed; }

.sidebar + .content { padding-left: 16em; padding-top: 12.375em; }

@media (max-width: 75em) { .sidebar { width: 7.1em; }
  .sidebar + .content { padding-top: 11.375em; padding-left: 7.1em; } }

@media (max-width: 1023px) { .sidebar { position: relative; width: 100%; }
  .sidebar + .content { padding-left: 0; padding-top: 0; } }

@font-face { font-family: 'icomoon'; src: url("../fonts/icomoon/fonts/icomoon.eot?-f1kwr1"); src: url("../fonts/icomoon/fonts/icomoon.eot?#iefix-f1kwr1") format("embedded-opentype"), url("../fonts/icomoon/fonts/icomoon.woff?-f1kwr1") format("woff"), url("../fonts/icomoon/fonts/icomoon.ttf?-f1kwr1") format("truetype"), url("../fonts/icomoon/fonts/icomoon.svg?-f1kwr1#icomoon") format("svg"); font-weight: normal; font-style: normal; }

[class^="icon-"]:not(.acf-icon):before, [class*="icon-"]:not(.acf-icon):before { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; display: inline-block; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

[class$="-icon"]:not(.acf-icon):after, [class*="-icon"]:not(.acf-icon):after { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; display: inline-block; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-user:before { content: ""; }

.user-icon:after { content: ""; }

.icon-key:before { content: ""; }

.key-icon:after { content: ""; }

.icon-arrow-up:before { content: ""; }

.arrow-up-icon:after { content: ""; }

.icon-arrow-right:before { content: ""; }

.arrow-right-icon:after { content: ""; }

.icon-arrow-down:before { content: ""; }

.arrow-down-icon:after { content: ""; }

.icon-arrow-left:before { content: ""; }

.arrow-left-icon:after { content: ""; }

.icon-remove:before { content: ""; }

.remove-icon:after { content: ""; }

.icon-minus:before { content: ""; }

.minus-icon:after { content: ""; }

.icon-plus:before { content: ""; }

.plus-icon:after { content: ""; }

.icon-settings:before { content: ""; }

.settings-icon:after { content: ""; }

/*********************
TYPOGRAPHY - http://cssfontstack.com/
*********************/
body { font-weight: 300; font-family: "Rubik", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; font-size: 16px; color: #808080; -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6 { font-family: "Rubik", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; }

h3 { font-size: 1.75em; color: #808080; }

.theme--blue h3 { color: #00A8E0; }

.theme--red h3 { color: #C66155; }

.theme--orange h3 { color: #FFB026; }

.theme--green h3 { color: #6AAE2D; }

a, button, input:focus, input[type="button"], input[type="reset"], input[type="submit"], textarea:focus, .button { transition: color 0.2s ease-out, background-color 0.2s ease-out; }

p { padding: 0; margin: 0 0 2em; }

p:last-child { margin-bottom: 0; }

pre, code { background: #eee; padding: 0.5em; }

code { word-break: break-word; overflow: hidden; }

iframe { border: none; }

.uppercase { text-transform: uppercase; }

.no-br { white-space: nowrap; }

embed, iframe, img, object, video { max-width: 100%; }

img { width: auto; /* IE8 */ height: auto; }

input[type="text"], input[type="password"], input[type="search"], input[type="email"], input[type="url"], input[type="tel"], input[type="number"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="file"], textarea, select, .select-wrap, .select2-container--default .select2-selection--single { max-width: 100%; width: 100%; padding: 0.5em; border: 2px solid #D9D9D9; border-radius: 15px; font-size: 1em; transition: border-color 0.2s ease-in-out; background-color: #FFFFFF; height: 3.125em; }

input[type="text"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="tel"]:focus, input[type="number"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="file"]:focus, textarea:focus, select:focus, .select-wrap:focus, .select2-container--default .select2-selection--single:focus { outline: none; border-color: #00A8E0; }

.theme--blue input[type="text"]:focus, .theme--blue input[type="password"]:focus, .theme--blue input[type="search"]:focus, .theme--blue input[type="email"]:focus, .theme--blue input[type="url"]:focus, .theme--blue input[type="tel"]:focus, .theme--blue input[type="number"]:focus, .theme--blue input[type="date"]:focus, .theme--blue input[type="month"]:focus, .theme--blue input[type="week"]:focus, .theme--blue input[type="time"]:focus, .theme--blue input[type="file"]:focus, .theme--blue textarea:focus, .theme--blue select:focus, .theme--blue .select-wrap:focus, .theme--blue .select2-container--default .select2-selection--single:focus { border-color: #00A8E0; }

.theme--red input[type="text"]:focus, .theme--red input[type="password"]:focus, .theme--red input[type="search"]:focus, .theme--red input[type="email"]:focus, .theme--red input[type="url"]:focus, .theme--red input[type="tel"]:focus, .theme--red input[type="number"]:focus, .theme--red input[type="date"]:focus, .theme--red input[type="month"]:focus, .theme--red input[type="week"]:focus, .theme--red input[type="time"]:focus, .theme--red input[type="file"]:focus, .theme--red textarea:focus, .theme--red select:focus, .theme--red .select-wrap:focus, .theme--red .select2-container--default .select2-selection--single:focus { border-color: #C66155; }

.theme--orange input[type="text"]:focus, .theme--orange input[type="password"]:focus, .theme--orange input[type="search"]:focus, .theme--orange input[type="email"]:focus, .theme--orange input[type="url"]:focus, .theme--orange input[type="tel"]:focus, .theme--orange input[type="number"]:focus, .theme--orange input[type="date"]:focus, .theme--orange input[type="month"]:focus, .theme--orange input[type="week"]:focus, .theme--orange input[type="time"]:focus, .theme--orange input[type="file"]:focus, .theme--orange textarea:focus, .theme--orange select:focus, .theme--orange .select-wrap:focus, .theme--orange .select2-container--default .select2-selection--single:focus { border-color: #FFB026; }

.theme--green input[type="text"]:focus, .theme--green input[type="password"]:focus, .theme--green input[type="search"]:focus, .theme--green input[type="email"]:focus, .theme--green input[type="url"]:focus, .theme--green input[type="tel"]:focus, .theme--green input[type="number"]:focus, .theme--green input[type="date"]:focus, .theme--green input[type="month"]:focus, .theme--green input[type="week"]:focus, .theme--green input[type="time"]:focus, .theme--green input[type="file"]:focus, .theme--green textarea:focus, .theme--green select:focus, .theme--green .select-wrap:focus, .theme--green .select2-container--default .select2-selection--single:focus { border-color: #6AAE2D; }

.item-table input[type="text"], .item-table input[type="password"], .item-table input[type="search"], .item-table input[type="email"], .item-table input[type="url"], .item-table input[type="tel"], .item-table input[type="number"], .item-table input[type="date"], .item-table input[type="month"], .item-table input[type="week"], .item-table input[type="time"], .item-table input[type="file"], .item-table textarea, .item-table select, .item-table .select-wrap, .item-table .select2-container--default .select2-selection--single { border-radius: 0; border: none; min-width: 100%; background-color: transparent; }

textarea { max-width: 100%; width: 100%; padding: 0.5em; border: 2px solid #D9D9D9; border-radius: 15px; font-size: 1em; transition: border-color 0.2s ease-in-out; background-color: #FFFFFF; min-height: 11.25rem; max-height: 11.25rem; resize: none; vertical-align: top; }

textarea.short { min-height: 3.125rem; max-height: 3.125rem; }

.item-table textarea { border-radius: 0; border: none; }

.placeholder { text-overflow: ellipsis; color: #CCC; }

::placeholder { text-overflow: ellipsis; color: #CCC; }

input[type="search"] { box-sizing: border-box; }

.styled-select { display: none; }

.select-wrap { position: relative; line-height: 1; padding-right: 3em; }

.select-wrap:before { content: ""; /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; display: inline-block; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; position: absolute; right: 1em; top: 50%; transform: translateY(-50%); transition: all .25s ease-in; color: #808080; }

.select-wrap select { cursor: pointer; -webkit-appearance: none; position: absolute; height: 100%; width: 100%; opacity: 0; top: 0; left: 0; transition: all .25s ease-in-out; }

.select-wrap ins { text-decoration: none; display: block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 2.1; }

.select-wrap:hover:before { color: #00A8E0; }

.checkbox-wrap, .radio-wrap { position: relative; display: inline-block; height: 1.3333em; width: 1.3333em; border: 2px solid #D9D9D9; vertical-align: middle; transition: all .25s ease-in-out; cursor: pointer; background: #FFF; margin-right: 0.1875em; border-radius: 4px; }

.checkbox-wrap.checked, .radio-wrap.checked { background: #D9D9D9; border: 2px solid #AAAAAA; }

.checkbox-wrap input, .radio-wrap input { position: absolute; opacity: 0; visibility: hidden; left: 0; top: 0; }

.checkbox-wrap + span, .radio-wrap + span, .checkgroup-wrap + span { cursor: pointer; display: inline-block; vertical-align: top; margin-top: 0.15em; transition: inherit; }

.checkbox-wrap:before { content: ""; position: absolute; width: 45%; height: 85%; left: 30%; top: 0; overflow: hidden; transition: all .25s ease-in-out; opacity: 0; border-bottom: 3px solid #000000; border-right: 3px solid #000000; transform: rotate(45deg); }

.checkbox-wrap.checked:before { opacity: 1; }

.checkbox { margin-top: 1em; margin-left: 1em; margin-right: 1em; display: inline-block; }

.radio-wrap { border-radius: 50%; }

.radio-wrap:before { content: ""; border-radius: 50%; padding: 1px; position: absolute; background: #FFF; width: 100%; height: 100%; left: 0; top: 0; color: #FFF; overflow: hidden; transition: all .25s ease-in-out; border: 0px solid #FFF; }

.radio-wrap.checked:before { border: 5px solid #D9D9D9; }

.mvpos-form__row > * { margin-bottom: 1.875rem; }

.mvpos-form__row:last-child > *:last-child { margin-bottom: 0; }

.sale-change span { line-height: 3.375rem; font-size: 2.625em; }

.sale-change span:before { content: "$"; }

.mvpos-form { margin-bottom: 4em; }

.mvpos-form__error { color: #F00; font-size: 0.9375em; padding: 0.533em 0 0 0.533em; display: none; }

.mvpos-form__row:not(.row) { zoom: 1; max-width: 100%; margin-left: auto; margin-right: auto; }

.mvpos-form__row:not(.row):before, .mvpos-form__row:not(.row):after { display: table; content: ""; }

.mvpos-form__row:not(.row):after { clear: both; }

.mvpos-form__row:not(.row):after { content: " "; display: block; clear: both; }

.mvpos-form__row--no-mar { margin-bottom: 0 !important; }

.mvpos-form__reveal { display: none; margin-top: 1.875rem; }

.mvpos-form__field--no-mar { margin-bottom: 0 !important; }

.mvpos-form__field--center { text-align: center; }

.mvpos-form__field--col:last-child { margin-right: 0; }

.mvpos-form__field--half { width: 49.15254%; float: left; margin-right: 1.69492%; }

.mvpos-form__field--third, .mvpos-form__field--change { width: 32.20339%; float: left; margin-right: 1.69492%; }

.mvpos-form__field--fourth, .mvpos-form__field--method, .mvpos-form__field--tender { width: 23.72881%; float: left; margin-right: 1.69492%; }

.mvpos-form__field--sixth, .mvpos-form__field--receipt { width: 15.25424%; float: left; margin-right: 1.69492%; }

.mvpos-form__field--icon { position: relative; }

.mvpos-form__field--icon > * { padding-left: 3.5em !important; }

.mvpos-form__field--icon:before { position: absolute; font-size: 1.8em; left: 0.5em; top: 50%; transform: translateY(-50%); color: #8C8C8C; }

@media (max-width: 35.5em) { .mvpos-form__field--method, .mvpos-form__field--tender, .mvpos-form__field--receipt, .mvpos-form__field--change { width: 48.27586%; float: left; margin-right: 3.44828%; }
  .mvpos-form__field--method:nth-child(2n), .mvpos-form__field--tender:nth-child(2n), .mvpos-form__field--receipt:nth-child(2n), .mvpos-form__field--change:nth-child(2n) { float: right; margin-right: 0; }
  .mvpos-form__field--method:not(:nth-child(n+2)), .mvpos-form__field--tender:not(:nth-child(n+2)), .mvpos-form__field--receipt:not(:nth-child(n+2)), .mvpos-form__field--change:not(:nth-child(n+2)) { margin-bottom: 1.875rem; } }

@media (max-width: 30em) { .mvpos-form__field--fourth { width: 48.27586%; float: left; margin-right: 3.44828%; }
  .mvpos-form__field--fourth:nth-child(2n) { float: right; margin-right: 0; }
  .mvpos-form__field--fourth:not(:nth-child(n+2)) { margin-bottom: 1.875rem; }
  .mvpos-form__field--half, .mvpos-form__field--third { width: 100%; float: left; margin-left: 0; margin-right: 0; }
  .mvpos-form__field--half:not(:last-child), .mvpos-form__field--third:not(:last-child) { margin-bottom: 1.875rem; } }

.mvpos-form__label { font-size: 0.9375em; padding: 0 0.533em 0.533em; font-weight: 400; display: block; color: #808080; }

.mvpos-form--complete { margin-bottom: 0; }

.mvpos-form--complete .cta { width: 100%; }

.mvpos-form--complete .sale-change span { line-height: 3.375rem; padding-left: 0.5rem; font-size: 2.625em; }

.mvpos-form--complete .sale-change span:before { content: "$"; }

.mvpos-form--vendor-info .mvpos-form__field--title { position: relative; padding-right: 10em; }

.mvpos-form--vendor-info .text-cta { position: absolute; right: 0; top: 50%; transform: translateY(-50%); }

@media (max-width: 30em) { .mvpos-form--vendor-info .mvpos-form__field--title { padding-right: 0; }
  .mvpos-form--vendor-info .text-cta { position: relative; right: auto; top: auto; transform: none; margin-top: 1em; } }

button, input[type="button"], input[type="reset"], input[type="submit"], .button { width: auto; line-height: 1; cursor: pointer; border: none; outline: none; padding: 0; background: none; }

.cta { font-size: 1.5625em; border: 0.2em solid #6AAE2D; color: #6AAE2D; padding: 0.45em 1em 0.5em; text-align: center; text-transform: uppercase; border-radius: 15px; font-family: "Rubik", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; font-weight: 500; transition: all 0.2s ease-in-out; }

.cta:not(:last-child) { margin-right: 1em; margin-bottom: 1em; }

.theme--blue .cta { color: #00A8E0; border-color: #00A8E0; }

.theme--red .cta { color: #C66155; border-color: #C66155; }

.theme--orange .cta { color: #FFB026; border-color: #FFB026; }

.theme--green .cta { color: #6AAE2D; border-color: #6AAE2D; }

.cta:after { content: ""; /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; display: inline-block; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-size: 0.76em; display: inline-block; margin-left: 0.3em; }

.cta--login { min-width: 8em; }

.cta--secondary { color: #808080 !important; border-color: #808080 !important; }

.cta--secondary:after { content: none; }

.cta--secondary:hover, .cta--secondary:focus, .cta--secondary:active { color: #AAAAAA !important; border-color: #AAAAAA !important; }

.cta--blue { color: #FFFFFF !important; background-color: #00A8E0; border-color: #00A8E0; }

.cta--blue:hover, .cta--blue:focus, .cta--blue:active { color: #00A8E0 !important; background-color: #FFFFFF; }

.cta:hover, .cta:focus, .cta:active { border-color: #00A8E0; color: #00A8E0; }

.text-cta { font-size: 1.125em; font-weight: 500; color: #808080; }

.text-cta:after { content: ""; /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; display: inline-block; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-size: 0.76em; display: inline-block; margin-left: 0.3em; }

.theme--blue .text-cta:hover { color: #00A8E0; }

.theme--red .text-cta:hover { color: #C66155; }

.theme--orange .text-cta:hover { color: #FFB026; }

.theme--green .text-cta:hover { color: #6AAE2D; }

.page-header { margin-bottom: 1.25rem; position: fixed; top: 0; width: calc(100% - 16em); z-index: 1; background-color: #00A8E0; }

.theme--blue .page-header { background-color: #00A8E0; }

.theme--red .page-header { background-color: #C66155; }

.theme--orange .page-header { background-color: #FFB026; }

.theme--green .page-header { background-color: #6AAE2D; }

.theme--gray .page-header { background-color: #AAAAAA; }

.page-header__page .wrapper { display: flex; align-items: center; justify-content: space-between; }

.page-header h2 { color: #FFFFFF; font-size: 2em; display: inline-block; vertical-align: middle; line-height: 4; font-weight: 400; }

.page-header__settings { text-decoration: none; color: #FFFFFF; }

.page-header__settings:before { font-size: 1.25em; margin-right: 0.25em; position: relative; top: 0.15em; }

.page-header__settings:hover { color: #D9D9D9; }

.settings .page-header__settings { display: none; }

@media (max-width: 75em) { .page-header { width: calc(100% - 7.1em); }
  .page-header h2 { line-height: 3.5; } }

@media (max-width: 1023px) { .page-header { position: static; width: 100%; }
  .page-header h2 { line-height: 2.5; } }

.menu-primary-menu-container { zoom: 1; margin: 0; padding: 0; list-style: none; width: 100%; }

.menu-primary-menu-container:before, .menu-primary-menu-container:after { display: table; content: ""; }

.menu-primary-menu-container:after { clear: both; }

.menu-primary-menu-container .menu-item { position: relative; display: inline-block; text-align: left; }

.menu-primary-menu-container .menu-item:hover > .sub-menu, .menu-primary-menu-container .menu-item:focus > .sub-menu, .menu-primary-menu-container .menu-item:active > .sub-menu, .menu-primary-menu-container .menu-item.sfHover > .sub-menu { left: 0; opacity: 1; transition: opacity 0.2s ease-in-out; display: block; }

.menu-primary-menu-container a { position: relative; display: block; }

.touch .menu-primary-menu-container .sub-menu { display: none; }

.menu-primary-menu-container .sub-menu { position: absolute; top: 100%; left: -9999px; z-index: 99; opacity: 0; background-color: #DDD; transition: opacity 0.2s ease-in-out, left 0 linear 0.2s; }

.menu-primary-menu-container .sub-menu .sub-menu { top: 0; background-color: #DDD; }

.menu-primary-menu-container .sub-menu .menu-item { position: relative; display: block; text-align: left; transition: all 0.2s ease-in-out; }

.menu-primary-menu-container .sub-menu .menu-item:hover, .menu-primary-menu-container .sub-menu .menu-item:focus, .menu-primary-menu-container .sub-menu .menu-item:active, .menu-primary-menu-container .sub-menu .menu-item.sfHover { background: #FFF; }

.menu-primary-menu-container .sub-menu .menu-item:hover > .sub-menu, .menu-primary-menu-container .sub-menu .menu-item:focus > .sub-menu, .menu-primary-menu-container .sub-menu .menu-item:active > .sub-menu, .menu-primary-menu-container .sub-menu .menu-item.sfHover > .sub-menu { left: 100%; }

.logo { max-width: 100%; display: inline-block; width: 16em; background: #FFF; vertical-align: middle; transition: none; }

.logo h1 { background-image: url("../images/logo.svg"); background-repeat: no-repeat; background-size: cover; margin: 0; overflow: hidden; text-indent: -9999px; white-space: nowrap; }

.login-form .logo { width: 27.5em; margin: 0 auto 3em; }

.login-form .logo h1 { padding-top: 41%; font-size: 0; }

.sidebar .logo { padding: 1.25em; }

.sidebar .logo h1 { height: 5.5em; }

@media (max-width: 75em) { .sidebar .logo { padding: 0.75em; } }

@media (max-width: 1023px) { .sidebar .logo { max-width: 45%; }
  .sidebar .logo h1 { background-size: contain; background-position: center center; padding-top: 41%; font-size: 0; } }

.login-form { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); max-width: 27.5em; width: calc(100% - 2em); }

.login-form .mvpos-form__field--submit { text-align: center; }

.time-bar { min-height: 3.125em; background: #808080; position: relative; }

.time-bar:before { content: ""; position: absolute; height: 100%; width: 100%; background-color: #808080; left: -1px; top: 0; transform: translateX(100%); }

.time-bar__date { color: #FFFFFF; font-size: 1.3125em; float: right; line-height: 2.38em; }

.time-bar__count { color: #FFFFFF; font-size: 1.3125em; float: left; line-height: 2.38em; }

.time-bar__select { float: right; margin-top: 0.55em; margin-left: 1.25rem; width: 200px; }

.time-bar__select .select2-container--default .select2-selection--single { height: 2em; border-radius: 10px; padding-top: 0.45em; padding-bottom: 0.45em; }

.time-bar__select .select2-container .select2-selection--single .select2-selection__rendered { line-height: 1; }

.item-table { width: 100%; border-collapse: separate; border-radius: 15px; margin-bottom: 1.25rem; }

.item-table tr.even { background: #eee; }

.item-table tr.even + tr.no-padding { background: #eee; }

.item-table tr:first-child th:first-child { border-top-left-radius: 15px; }

.item-table tr:first-child th:last-child { border-top-right-radius: 15px; }

.item-table th { background: #FFFFFF; color: #808080; font-size: 1.125em; font-weight: 400; padding: 0.5rem; white-space: nowrap; height: 3.125rem; vertical-align: middle; }

.theme--blue .item-table th { color: #00A8E0; }

.theme--red .item-table th { color: #C66155; }

.theme--orange .item-table th { color: #FFB026; }

.theme--green .item-table th { color: #6AAE2D; }

.item-table td, .item-table th { border: 1px solid #D9D9D9; text-align: left; }

.item-table td { vertical-align: top; }

.item-table td[data-detail="Item #"] { max-width: 8em; }

.item-table td[data-detail="Vendor"], .item-table td[data-detail="Item Name"] { min-width: 9.5em; max-width: 9.5em; }

.item-table td[data-detail="QTY"] { max-width: 4.5em; }

.item-table td[data-detail="Discount"] { max-width: 5em; }

.item-table td[data-detail="Price"], .item-table td[data-detail="Subtotal"], .item-table td[data-detail="Total"] { width: 5.6em; }

.item-table td[data-detail="Taxable"] { vertical-align: middle; text-align: center; }

.item-table td[data-detail="Remove"] { vertical-align: middle; text-align: center; min-width: 3em; text-align: center; }

.item-table td[data-detail="Remove"] .icon-remove { font-size: 1.1em; color: #C66155; }

.item-table td[data-detail="Remove"] .icon-remove:hover { opacity: 0.7; }

.item-table tfoot tr button { width: 100%; border-radius: 0 0 15px 15px; }

.item-table tfoot td { background-color: #00A8E0; border: none; padding: 0; height: 1.5em; }

.item-table tfoot td:first-child { border-bottom-left-radius: 15px; }

.item-table tfoot td:last-child { border-bottom-right-radius: 15px; }

.theme--blue .item-table tfoot td { background-color: #00A8E0; color: #FFFFFF; height: 3.125em; }

.theme--red .item-table tfoot td { background-color: #C66155; color: #FFFFFF; }

.theme--orange .item-table tfoot td { background-color: #FFB026; color: #FFFFFF; }

.theme--green .item-table tfoot td { background-color: #6AAE2D; color: #FFFFFF; }

.item-table--dtable table { width: 100%; }

.item-table--dtable table th { font-size: 1em; }

.item-table--dtable table tr:nth-child(even) { background: #eee; }

.item-table--dtable tr .btn-toggle-sale-breakdown__container { text-align: center; }

.item-table--dtable tr .btn-toggle-sale-breakdown:before { content: ""; /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; display: inline-block; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; font-size: 1.7em; color: #6AAE2D; }

.item-table--dtable tr .btn-toggle-sale-breakdown:hover { opacity: 0.7; }

.item-table--dtable tr.shown .btn-toggle-sale-breakdown:before { content: ""; /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; display: inline-block; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.item-table--dtable td.no-padding { padding: 0; }

.item-table--dtable td.no-padding .slider { padding: 15px; }

@media (max-width: 1400px) { .item-table--dtable { font-size: 0.75em; } }

@media (max-width: 1023px) { .item-table--responsive { display: block; }
  .item-table--responsive thead, .item-table--responsive tbody, .item-table--responsive tfoot, .item-table--responsive th, .item-table--responsive td, .item-table--responsive tr { display: block; }
  .item-table--responsive thead tr { position: absolute; top: -9999px; left: -9999px; }
  .item-table--responsive tr { border: 1px solid #D9D9D9; }
  .item-table--responsive td { /* Behave  like a "row" */ border: none; border-bottom: 1px solid #D9D9D9; position: relative; padding-left: 7.25em; min-height: 3.125em; max-width: none !important; width: auto !important; }
  .item-table--responsive td[data-detail="Remove"] { padding-top: 1em; padding-bottom: 1em; }
  .item-table--responsive td:before { content: attr(data-detail); /* Now like a table header */ position: absolute; top: 0; left: 0; width: 7.25em; white-space: nowrap; height: 100%; border-right: 1px solid #D9D9D9; padding: 1em 1.25rem; text-align: left; }
  .item-table--responsive tfoot tr { border: none; }
  .item-table--responsive tfoot td:before { border: none; } }

.select2-container { width: calc(100% + 1px) !important; }

.select2-dropdown { border-color: #D9D9D9; }

.select2-search--dropdown .select2-search__field { height: 2em; border-radius: 8px; }

.select2-container--default .select2-selection--single .select2-selection__arrow { height: 100%; top: 0; width: 1.5em; }

.select2-container .select2-selection--single .select2-selection__rendered { padding-left: 0; line-height: 2; }

.select2-container--default .select2-results__option[aria-selected=true] { background-color: #D9D9D9; color: #000000; }

.select2-container--default .select2-results__option--highlighted[aria-selected] { background-color: #00A8E0; color: #FFFFFF; }

.select2-container--open .select2-dropdown--below { border-radius: 0 0 9px 9px; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); overflow: hidden; }

.order-details-block { width: calc(50% - 1.25rem / 2); margin-bottom: 1.25rem; border: 1px solid #D9D9D9; border-radius: 15px; padding-bottom: 1.25rem; }

.order-details-block h3 { padding: 1.25rem; }

.order-details-block__header { position: relative; }

.order-details-block__header .text-cta { position: absolute; right: 1.25rem; top: 50%; transform: translateY(-50%); }

.touchevents .order-details-block__header .text-cta { display: none !important; }

.order-details-block--info { float: right; }

.order-details-block--info h3 { border-bottom: 1px solid #D9D9D9; margin-bottom: 1.25rem; }

.order-details-block--info button { width: 100%; }

.order-details-block--totals { float: left; padding-bottom: 0; }

.order-details-block--day-report { width: 100%; padding-bottom: 0; margin-bottom: 4em; }

.order-details-block--complete { float: right; }

.order-details-block--complete h3 { border-bottom: 1px solid #D9D9D9; margin-bottom: 1.25rem; }

@media (max-width: 1023px) { .order-details-block { width: 100%; float: none; } }

.totals-table { width: 100%; border-collapse: separate; }

.totals-table td, .totals-table th { border: 1px solid #D9D9D9; vertical-align: middle; padding: 1.25rem; font-size: 2em; }

.totals-table th { font-size: 1.8em; text-align: left; }

.totals-table td { text-align: right; }

.totals-table tfoot { color: #FFFFFF; background-color: #00A8E0; }

.totals-table tfoot td, .totals-table tfoot th { font-size: 2.625em; }

.totals-table tfoot th { text-transform: uppercase; border-bottom-left-radius: 15px; }

.totals-table tfoot td { border-bottom-right-radius: 15px; }

.theme--blue .totals-table tfoot { background-color: #00A8E0; }

.theme--red .totals-table tfoot { background-color: #C66155; }

.theme--orange .totals-table tfoot { background-color: #FFB026; }

.theme--green .totals-table tfoot { background-color: #6AAE2D; }

@media (max-width: 35.5em) { .totals-table { font-size: 0.555em; } }

.twitter-typeahead .tt-query, .twitter-typeahead .tt-hint { margin-bottom: 0; }

.tt-hint { display: block; width: 100%; height: 38px; padding: 8px 12px; font-size: 14px; line-height: 1.428571429; color: #999; vertical-align: middle; background-color: #ffffff; border-radius: 4px; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.tt-menu { min-width: calc(100% + 2px); margin-left: -1px; padding: 0; background-color: #ffffff; border-radius: 0 0 9px 9px; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); border-top: none; overflow: hidden; border: 1px solid #D9D9D9; border-top: none; }

.tt-suggestion { display: block; padding: 0.5em; margin: 0; cursor: pointer; }

.tt-suggestion:hover { color: #fff; background-color: #00A8E0; }

.tt-suggestion.tt-is-under-cursor a { color: #fff; }

table.dataTable { margin-top: 1.25rem; float: left; width: 100%; }

table.dataTable thead th, table.dataTable tfoot th { font-weight: 400; }

table.dataTable thead th, table.dataTable thead td { border-color: #D9D9D9; }

table.dataTable tfoot th, table.dataTable tfoot td { border-color: #D9D9D9; }

table.dataTable.no-footer { border-bottom: none; }

@media (max-width: 1400px) { table.dataTable thead th, table.dataTable thead td, table.dataTable tbody th, table.dataTable tbody td, table.dataTable tfoot th, table.dataTable tfoot td { padding: 8px 16px 8px 8px; } }

.dataTables_wrapper { margin-bottom: 4em; overflow: auto; -webkit-overflow-scrolling: touch; }

.dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_length select { width: auto; height: 2em; }

.sidebar { background-color: #D9D9D9; height: 100%; }

.sidebar .primary-nav__link { display: block; text-decoration: none; color: #000000; padding-left: 1.3125em; height: 3.125rem; line-height: 3.125rem; position: relative; z-index: 1; transition: 0.15s 0.15s ease-out; }

.sidebar .primary-nav__item { position: relative; font-size: 1.3125em; }

.sidebar .primary-nav__item--sub-item { font-size: 0.9em; }

.sidebar .primary-nav__item--sub-item .primary-nav__link { padding-left: 2em; border-top: 1px solid rgba(255, 255, 255, 0.5); height: auto; }

.sidebar .primary-nav__item--sub-item .primary-nav__link:hover { background-color: rgba(255, 255, 255, 0.25); }

.sidebar .primary-nav__item:before { content: ""; position: absolute; width: 0.667em; height: 100%; left: 0; top: 0; z-index: 1; transition: 0.15s 0.15s ease-out; }

.sidebar .primary-nav__item--current .primary-nav__link, .sidebar .primary-nav__item:hover .primary-nav__link { color: #FFFFFF; transition: 0.15s ease-out; }

.sidebar .primary-nav__item--current:before, .sidebar .primary-nav__item:hover:before { width: 100% !important; transition: 0.15s ease-out; }

.sidebar .primary-nav__item--blue:before { background-color: #00A8E0; }

.sidebar .primary-nav__item--yellow .primary-nav__link { color: #000000 !important; }

.sidebar .primary-nav__item--yellow:before { background-color: #EFEF0A; }

.sidebar .primary-nav__item--red:before { background-color: #C66155; }

.sidebar .primary-nav__item--orange:before { background-color: #FFB026; }

.sidebar .primary-nav__item--green:before { background-color: #6AAE2D; }

.sidebar .primary-nav__item--log-out { position: absolute; bottom: 0; width: 100%; }

.sidebar .primary-nav__item--log-out .primary-nav__link { background-color: #808080; color: #FFFFFF; text-align: center; padding: 1.5em 2em 1.5em 0.6904em; text-transform: uppercase; position: relative; height: auto; font-weight: 500; }

.sidebar .primary-nav__item--log-out .primary-nav__link:after { margin-left: 0.5em; position: absolute; top: 50%; transform: translateY(-50%); }

.sidebar .primary-nav__item--log-out .primary-nav__link:hover { background-color: #FFFFFF; color: #808080; }

.sidebar .primary-nav__sub-menu { display: none; }

@media (max-width: 75em) { .sidebar .primary-nav__link { padding-left: 1em; }
  .sidebar .primary-nav__item { font-size: 1em; line-height: 1.7; }
  .sidebar .primary-nav__item:before { width: 0.4em; }
  .sidebar .primary-nav__item--sub-item .primary-nav__link { padding-left: 1.3125em; } }

@media (max-width: 1023px) { .sidebar { background-color: #FFFFFF; }
  .sidebar .primary-nav { display: none; } }

.mm-menu { background-color: #D9D9D9; }

.mm-menu .arrow-right-icon { background-color: #808080; color: #FFFFFF !important; font-weight: 500; position: relative; text-transform: uppercase; }

.mm-menu .arrow-right-icon:after { margin-left: 0.5em; position: absolute; top: 50%; transform: translateY(-50%); }

.mm-menu .primary-nav__item--log-out { display: none; }

.menu-btn { position: absolute; right: 1.25rem; top: 50%; transform: translateY(-50%); display: none; height: 25px; width: 32px; cursor: pointer; }

.menu-btn span, .menu-btn:before, .menu-btn:after { position: absolute; display: block; width: 32px; height: 5px; background-color: #808080; left: 50%; top: 10px; margin: 0 0 0 -16px; opacity: 1; transition: all 0.2s ease-in-out; }

.menu-btn:before { content: ""; transform: translate(0, -10px); }

.menu-btn:after { content: ""; transform: translate(0, 10px); }

.mm-opened .menu-btn span, .mm-opened .menu-btn:before, .mm-opened .menu-btn:after { background-color: #808080; }

.mm-opened .menu-btn span { opacity: 0; }

.mm-opened .menu-btn:before { transform: rotate(45deg); }

.mm-opened .menu-btn:after { transform: rotate(-45deg); }

@media (max-width: 1023px) { .menu-btn { display: initial; } }

@media print { * { background: transparent !important; color: black !important; text-shadow: none !important; filter: none !important; }
  a:link, a:visited { color: black !important; text-decoration: underline; }
  pre, blockquote { border: 1px solid black; page-break-inside: avoid; }
  @page { margin: 2cm 1.5cm; }
  p, h2, h3 { orphans: 4; widows: 2; }
  h2, h3 { page-break-after: avoid; }
  .menu, .widget-area, .sidebar, .footer-widgets, .site-footer { display: none !important; }
  .hide-print { display: none !important; }
  .title-area { float: none; }
  .site-header .site-title img { display: block; margin: 0 auto 1em; }
  .content { width: 100% !important; }
  .entry-title a[href]:after, .entry-content a[href]:after { content: " (" attr(href) ")"; }
  .entry-title abbr[title]:after, .entry-content abbr[title]:after { content: " (" attr(title) ")"; }
  .entry-title a[href^="#"]:after, .entry-title a[href^="javascript"]:after, .entry-content a[href^="#"]:after, .entry-content a[href^="javascript"]:after { content: ""; } }
